import React, { useContext, useEffect } from 'react';
import { Token } from '../../helpers';
import { TypeSelectorContext } from './context/TypeSelectorContext';
import { BaseStoreContext, LanguageContext } from '../../contexts';
import { ClassNameInterface } from '../../interfaces';
import './typeSelector.scss';
import { SzButton } from 'react-theme-components';

export const MovementTypeSelector: React.FC<ClassNameInterface> = ({
  className,
}) => {
  const t = new Token().getDecoded().roles || [];
  if (
    !(
      t.includes('ROLE_MOVEMENT_CUSTOMER') &&
      t.includes('ROLE_MOVEMENT_SUPPLIER')
    )
  ) {
    return null;
  }

  const { translate } = useContext(LanguageContext);
  const { selected, setSelected } = useContext(TypeSelectorContext);
  const {setSelectedMaterials} = useContext(BaseStoreContext);

  useEffect(() => {
    setSelected('ROLE_MOVEMENT_SUPPLIER');
  }, []);

  const handleTypeSelected = (type) => {
    setSelected(type);
    setSelectedMaterials([], 'analysis');
    setSelectedMaterials([], 'movement');
    setSelectedMaterials([], 'circularEconomy');
  };

  return (
    <div className={className || 'col-12 d-flex pt-4'}>
      <div className="d-flex m-0 ml-auto">
        <div className="d-flex">
          {[
            {
              label: 'customer',
              value: 'ROLE_MOVEMENT_CUSTOMER',
            },
            {
              label: 'supplier',
              value: 'ROLE_MOVEMENT_SUPPLIER',
            },
          ].map(({ label, value }, key) => (
            <SzButton
              className="my-auto type-selector"
              key={key}
              onClick={(): void => handleTypeSelected(value)}
              variant={selected === value ? 'primary' : 'secondary'}
            >
              <span>{translate(`charts.typeSelector.${label}`)}</span>
            </SzButton>
          ))}
        </div>
      </div>
    </div>
  );
};
