import React, { useState } from 'react';
import { Token } from '../../../helpers';
import { TypeSelectorContext } from './TypeSelectorContext';

export const MovementTypeSelectorProvider: React.FC = ({ children }) => {
  const roles = new Token()
    .getDecoded()
    .roles.filter((r) =>
      ['ROLE_MOVEMENT_SUPPLIER', 'ROLE_MOVEMENT_CUSTOMER'].includes(r)
    );
  const [selected, setSelected] = useState<string>(roles[0] || '');

  return (
    <TypeSelectorContext.Provider value={{ selected, setSelected }}>
      {children}
    </TypeSelectorContext.Provider>
  );
};
