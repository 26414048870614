import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Route,
  RouteProps,
  Router,
  Switch,
} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory, History } from 'history';
import './main.scss';
import {
  BaseStoreProvider,
  LanguageProvider,
  UserProvider,
  LanguageContext,
} from './contexts';
import { AppInsightProvider } from './contexts/AppInsightContext';
import withTracker from './routes/withTracker.jsx';
import { ROLES, ROUTES } from './actions/BCM_Constant';
import CustomRoute from './routes/CustomRoute';
import {
  Account,
  Analysis,
  Catalog,
  CatalogItem,
  Contact,
  DemandsExternal,
  DemandsInternal,
  Welcome,
} from './pages';
import { passwordRoutes } from './routes/passwordRoutes';
import { Movement } from './pages/Movement';
import { CreateDemands } from './pages/Demands/CreateDemand';

const history: History = createBrowserHistory();

declare global {
  interface Window {
    dataLayer: any[];
    QSI: any;
    tarteaucitronForceLanguage: any;
  }
}

const RoutingComponent: React.FC = () => (
  <Switch>
    <BrowserRouter {...{ history }}>
      <CustomRoute
        exact
        path={ROUTES.Welcome}
        condition={[ROLES.ROLE_CUSTOMER, ROLES.ROLE_SUPPLIER]}
      >
        <Welcome />
      </CustomRoute>
      <CustomRoute
        exact
        path={ROUTES.Analysis}
        condition={[ROLES.ROLE_CUSTOMER, ROLES.ROLE_SUPPLIER]}
      >
        <Analysis />
      </CustomRoute>
      <CustomRoute
        exact
        path={ROUTES.CatalogItem}
        condition={[ROLES.ROLE_CUSTOMER, ROLES.ROLE_SUPPLIER]}
      >
        <CatalogItem />
      </CustomRoute>
      <CustomRoute
        exact
        path={ROUTES.Catalog}
        condition={[ROLES.ROLE_CUSTOMER, ROLES.ROLE_SUPPLIER]}
      >
        <Catalog />
      </CustomRoute>
      <CustomRoute
        exact
        path={ROUTES.Account}
        condition={[
          ROLES.ROLE_CUSTOMER,
          ROLES.ROLE_SUPPLIER,
          ROLES.ROLE_MOVEMENT_CUSTOMER,
          ROLES.ROLE_MOVEMENT_SUPPLIER,
          ROLES.ROLE_EXTERNAL_OPERATOR,
          ROLES.ROLE_INTERNAL_OPERATOR,
        ]}
      >
        <Account />
      </CustomRoute>
      <CustomRoute
        exact
        path={ROUTES.Movement}
        condition={[ROLES.ROLE_MOVEMENT_CUSTOMER, ROLES.ROLE_MOVEMENT_SUPPLIER]}
      >
        <Movement />
      </CustomRoute>
      <CustomRoute
        exact
        path={ROUTES.DemandsExternal}
        condition={[ROLES.ROLE_EXTERNAL_OPERATOR]}
      >
        <DemandsExternal />
      </CustomRoute>
      <CustomRoute
        exact
        path={ROUTES.CreateDemands}
        condition={[ROLES.ROLE_EXTERNAL_OPERATOR]}
      >
        <CreateDemands />
      </CustomRoute>
      <CustomRoute
        exact
        path={ROUTES.DemandsInternal}
        condition={[ROLES.ROLE_INTERNAL_OPERATOR]}
      >
        <DemandsInternal />
      </CustomRoute>
      <CustomRoute
        exact
        path={ROUTES.Contact}
        condition={[
          ROLES.ROLE_CUSTOMER,
          ROLES.ROLE_SUPPLIER,
          ROLES.ROLE_MOVEMENT_CUSTOMER,
          ROLES.ROLE_MOVEMENT_SUPPLIER,
          ROLES.ROLE_EXTERNAL_OPERATOR,
          ROLES.ROLE_INTERNAL_OPERATOR,
          ROLES.ROLE_EXTERNAL_OPERATOR,
        ]}
      >
        <Contact />
      </CustomRoute>
      {passwordRoutes.map(
        ({ component, ...route }: RouteProps, index: number) => (
          <Route key={index} component={withTracker(component)} {...route} />
        )
      )}
    </BrowserRouter>
  </Switch>
);

const App: React.FC = () => {
  const { selectedLanguage } = useContext(LanguageContext);
  useEffect(() => {
    window.tarteaucitronForceLanguage = (
      selectedLanguage.value as string
    ).split('-')[0];
  }, [window]);

  return (
    <UserProvider>
      <BaseStoreProvider>
        <LanguageProvider>
          <BrowserRouter>
            <AppInsightProvider>
              <RoutingComponent />
            </AppInsightProvider>
          </BrowserRouter>
        </LanguageProvider>
      </BaseStoreProvider>
    </UserProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
