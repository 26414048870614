import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import { Token } from '../../../helpers';

interface TypeSelectorContextInterface {
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
}

export const TypeSelectorContext = createContext<TypeSelectorContextInterface>({
  selected: '',
  setSelected: () => null,
});

export const TypeSelectorProvider: React.FC = ({ children }) => {
  const roles = new Token()
    .getDecoded()
    .roles.filter((r) => ['ROLE_SUPPLIER', 'ROLE_CUSTOMER'].includes(r));
  const [selected, setSelected] = useState<string>(roles[0] || '');

  return (
    <TypeSelectorContext.Provider value={{ selected, setSelected }}>
      {children}
    </TypeSelectorContext.Provider>
  );
};
